const simulateEllipsis = ( formElementID ) => {
	const formElement = document.getElementById( formElementID.slice( 1 ) );
	if ( !formElement ) { return; }

	const addFakeDivs = () => {
		const selectWrappers = formElement.querySelectorAll( '.field-wrap.list-wrap' );
		if ( !selectWrappers ) { return; }
		selectWrappers.forEach(( selectWrapper ) => {
			const selectElementWrapper = selectWrapper.querySelector( '.nf-field-element' );
			if ( !selectElementWrapper ) { return; }

			const select = selectElementWrapper.querySelector( 'select' );
			if ( !select ) { return; }

			selectElementWrapper.querySelectorAll( '.fake-select-placeholder' ).forEach( ( el ) => el.remove() );

			const fakeDiv = document.createElement( 'div' );
			fakeDiv.className = 'fake-select-placeholder';
			fakeDiv.textContent = select.options[ select.selectedIndex ].text;
			selectElementWrapper.style.position = 'relative';
			select.parentNode.insertBefore( fakeDiv, select.nextSibling );

			select.addEventListener( 'change', () => {
				fakeDiv.textContent = select.options[ select.selectedIndex ].text;
			} );
		} );
	};

	addFakeDivs();

	const isMultiStep = formElement.classList.contains( 'multi-step-form' );
	if ( isMultiStep ) {
		formElement.addEventListener( 'click', ( event ) => {
			if ( event.target.closest( '.nf-next, .nf-previous' ) ) {
				setTimeout( () => addFakeDivs(), 20 );
			}
		} );
	}
};

export default simulateEllipsis;
